import React from "react";
import "./Hero.css";
import ship from "../../../Assets/ship.png";
import mainImage from "../../../Assets/mainImage.png";
function Hero() {
  return (
    <div id="hero" className="hero">
      {/* <img src={ship} className="ship" alt="" /> */}
      <div className="hero-left">
        <h1>
          Explore the special <br /> effects and values <br /> of{" "}
          <strong className="s_color">Pikachu Coin</strong>
          <br /> brings
        </h1>
        {/* social buttons ---------------------- */}
        <div className="btn-div-hero desk">
          <a href="https://twitter.com/pikachuco_in" target="_blank">
            <button className="hero-btn twitter">
              <i className="fab fa-twitter header-social"></i>
              Follow Us
            </button>
          </a>
          <a href="#" target="_blank">
            <button className="hero-btn telegram">
              <i className="fab fa-telegram header-social"></i>
              Telegram
            </button>
          </a>
        </div>
        {/* social buttons ---------------------- */}
      </div>
      <div className="hero-right GFG">
        <img src={mainImage} alt="" />
      </div>

      
      <div className="arrow-container">
        <div className="arrow-down"></div>
      </div>


      <div className="btn-div-hero mb">
          <a href="https://twitter.com/pikachuco_in" target="_blank">
            <button className="hero-btn twitter">
              <i className="fab fa-twitter header-social"></i>
              Follow Us
            </button>
          </a>
          <a href="#" target="_blank">
            <button className="hero-btn telegram">
              <i className="fab fa-telegram header-social"></i>
              Telegram
            </button>
          </a>
        </div>
    </div>
  );
}

export default Hero;
