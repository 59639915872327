import React from "react";
import "./About.css";
import ImageOne from "../../../Assets/ImageOne.png";
import ImageTwo from "../../../Assets/ImageTwo.png";
import ImageThree from "../../../Assets/ImageThree.png";
import ImageFour from "../../../Assets/ImageFour.png";
import "@splidejs/splide/dist/css/splide.min.css";

function About() {
  return (
    <div className="about">
      <div className="about-top">
        <div className="about-left">
          <img src={ImageOne} alt="" />
        </div>
        <div className="about-right">
          <h1>Fun and closeness</h1>
          <p>
            Pikachu Coin is not only a currency, but also a symbol of fun and
            close communication. With the familiar symbol of the Pikachu
            character, each transaction becomes interesting and engaging.
          </p>
        </div>
      </div>
      <div className="about-swap">
        <div className="about-swap-left">
          <h1>Strong community</h1>
          <p>
            Pikachu Coin is not only a digital product, but also a vibrant
            community. We create an open and sharing environment where people
            can connect, exchange ideas and build together.
          </p>
        </div>
        <div className="about-swap-right">
          <img src={ImageTwo} alt="" />
        </div>
      </div>
      <div className="about-top">
        <div className="about-left">
          <img src={ImageThree} alt="" />
        </div>
        <div className="about-right">
          <h1>Convenient payment</h1>
          <p>
            Pikachu Coin is not only a "meme coin" but also a convenient means
            of payment. With blockchain technology, transactions become fast,
            safe and do not require intermediaries
          </p>
        </div>
      </div>
      <div className="about-swap">
        <div className="about-swap-left">
          <h1>Feasibility and potential</h1>
          <p>
            Pikachu Coin is more than just a joke. We are committed to
            developing and expanding this project, bringing value and attractive
            investment opportunities to our community.
          </p>
        </div>
        <div className="about-swap-right">
          <img src={ImageFour} alt="" />
        </div>
      </div>
      <div className="about-last">
        <p>
          Join us on a new journey, explore the world of Pikachu Coin and
          experience the magic of commemorative cryptocurrency
        </p>
        <div className="about-icon">
          <a href="https://twitter.com/pikachuco_in" target="_blank">
            <i className="fab fa-twitter header-social"></i>
          </a>
          <i className="fab fa-telegram header-social"></i>
        </div>
      </div>
    </div>
  );
}

export default About;
