import React from "react";
import Hero from "./Hero/Hero.jsx";
import About from "./About/About.jsx";
import "./Home.css";
function Home() {
  return (
    <div className="home">
      <Hero />
      <About />
    </div>
  );
}

export default Home;
